<template>
  <div class="he-r">
    <div class="dynamic-con message-page">
      <h5 class="he-tit">私信消息</h5>
      <div class="message-list-box" style="margin:0 -30px">
        <ul class="message-list">
          <li v-for="item in messageList" :key="item.index" @click="mainInfo(item)">
			 <!-- {{item}} -->
            <span
              class="person-icons"
              :style="{
                background: 'url(' + (item.friendAvatar || avatar) + ') no-repeat center',
                backgroundSize: '100% 100%'
              }"
            ></span>
            <div class="message">
              <h5>
                <span class="tit">{{ item.friendName }}</span>
                <span class="time">{{ item.createTime | dateFormat('YYYY年MM月DD日') }}</span>
              </h5>
              <div class="message-con">
                <p>{{ item.content }}</p>
                <i class="num-i" v-if="item.newChatNum>0">{{ item.newChatNum }}</i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      avatar: require('../../assets/images/avatar.png'),
      messageList: [
        // {
        //   icon: require('../../assets/images/person.png'),
        //   name: '南平市非物质文化遗产保护中心',
        //   text: '南平市非物质文化遗产传承人认证审核通知',
        //   time: '2020年10月29日',
        //   num: '12',
        //   me: true
        // },
        // {
        //   icon: require('../../assets/images/person.png'),
        //   name: '南平市非物质文化遗产保护中心',
        //   text: '南平市非物质文化遗产传承人认证审核通知',
        //   time: '2020年10月29日',
        //   num: '12',
        //   me: false
        // },
        // {
        //   icon: require('../../assets/images/person.png'),
        //   name: '南平市非物质文化遗产保护中心',
        //   text: '南平市非物质文化遗产传承人认证审核通知',
        //   time: '2020年10月29日',
        //   num: '12',
        //   me: false
        // },
        // {
        //   icon: require('../../assets/images/person.png'),
        //   name: '南平市非物质文化遗产保护中心',
        //   text: '南平市非物质文化遗产传承人认证审核通知',
        //   time: '2020年10月29日',
        //   num: '12',
        //   me: false
        // },
        // {
        //   icon: require('../../assets/images/icon3.png'),
        //   name: '南平市非物质文化遗产保护中心',
        //   text: '这个人很懒，什么都没有留下',
        //   time: '2020年10月29日',
        //   num: '12',
        //   me: true
        // },
        // {
        //   icon: require('../../assets/images/icon4.png'),
        //   name: '南平市非物质文化遗产保护中心',
        //   text: '这个人很懒，什么都没有留下',
        //   time: '2020年10月29日',
        //   num: '12',
        //   me: true
        // },
        // {
        //   icon: require('../../assets/images/icon5.png'),
        //   name: '南平市非物质文化遗产保护中心',
        //   text: '这个人很懒，什么都没有留下',
        //   time: '2020年10月29日',
        //   num: '12',
        //   me: true
        // }
      ]
    }
  },
  mounted() {
    this.getMessageList()
  },
  methods: {
    getMessageList() {
      this.axios.get('/api/portal/chat/auth/list').then(res => {
        this.messageList = res.data.list
      })
    },
    mainInfo(item) {
      this.$router.push(`/sendMessage/${item.friendId}/${item.friendName}`)
    }
  }
}
</script>
